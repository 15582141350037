<template>
  <div class="weui-tab">
    <div class="weui-tab__bd public-container">
      <div :class="['alipayAccOld', {'hidden' : blockStep !== 1}]">
        <div class="weui-cells__title">身份安全验证</div>
        <div
          class="
            public-areas public-area-user-list
            margin-bottom-10 margin-top-10
          "
          v-if="alipayAccOld"
        >
          <div class="weui-cells margin-top-0">
            <div class="weui-cell">
              <div class="weui-cell__hd">
                <label class="weui-label">支付宝</label>
              </div>
              <div class="weui-cell__bd">
                <p>{{alipayAccOld}}</p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="
            public-areas public-area-user-list
            margin-bottom-10 margin-top-10
          "
        >
          <div class="weui-cells margin-top-0">
            <div class="weui-cell">
              <div class="weui-cell__hd">
                <label class="weui-label">验证码</label>
              </div>
              <div class="weui-cell__bd">
                <p>
                  <input
                    class="weui-input"
                    id="old-mcode"
                    type="text"
                    maxlength="6"
                    placeholder="请输入验证码"
                    v-model="captcha"
                  />
                </p>
              </div>
              <div class="weui-cell__ft" @click="sendSms" v-if="!countDownStatus">
                <button class="weui-vcode-btn btn-verify public-btn-vcode">
                  获取验证码
                </button>
              </div>
              <div class="weui-cell__ft" v-else>
                <span class="weui-vcode-btn btn-verify-new public-btn-vcode disabled">{{seconds}}s 后发送</span>
              </div>
            </div>
          </div>
        </div>
        <div class="public-area-submit-box">
          <div class="weui-cells margin-top-0">
            <div class="weui-cell">
              <div class="weui-cell__bd">
                <a
                  href="javascript:;"
                  class="weui-btn weui-btn_primary public-btn-user-edit-next"
                  @click="nextStep"
                  >下一步</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div :class="['set-new', {'hidden' :  blockStep !== 2 }]">
        <div
          class="
            public-areas public-area-user-list
            margin-bottom-10 margin-top-10
          "
        >
          <div class="weui-cells margin-top-0">
            <div class="weui-cell" href="javascript:;">
              <div class="weui-cell__hd">
                <label class="weui-label">支付宝</label>
              </div>
              <div class="weui-cell__bd">
                <p>
                  <input
                    class="weui-input"
                    id="alipay"
                    type="text"
                    maxlength="32"
                    placeholder="请输入支付宝账号"
                    v-model="alipayAcc"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="public-area-submit-box">
          <div class="weui-cells margin-top-0">
            <div class="weui-cell">
              <div class="weui-cell__bd">
                <input
                  type="hidden"
                  name="action"
                  id="action"
                  value="edit_alipay"
                />
                <a
                  href="javascript:;"
                  class="weui-btn weui-btn_primary public-btn-user-edit"
                  @click="willSave"
                  >保存</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <tabbar :selectedVal="'users'"></tabbar>
  </div>
</template>

<script>
import { getUserInfo, getBindSmsCaptcha, setAlipayAcc  } from '../api/index'
import tabbar from '../components/tabbar/index.vue'
import wxauth from '../mixin/wxauth'
export default {
  mixins: [wxauth],
  name: "editAlipay",
  components: {
    tabbar
  },
  data () {
    return {
      countDownStatus: false, // 发送验证码的60秒倒计时内
      timer: null,
      seconds: 60,
      hasAcc: false, // 是否有支付宝账号，
      bindMobile: '', // 已经绑定的手机号
      alipayAccOld: '', // 已经绑定的支付宝账号
      alipayAcc: '',
      captcha: '',
      captchaUniqueId: '',
      blockStep: 1, // 页面展示第几步
    }
  },
  created () {
    this.doGetUserInfo()
  },
  mounted () {
    // this.$toast.info('验证码已发送，请查收')
    // this.$toast.loading('加载中', { duration: 0 });
  },
  methods: {
    // 获取用户信息
    async doGetUserInfo () {
      let params = {
        param: {}
      }
      let res = {}
      try {
        res = await getUserInfo(params)
        this.$toast.loading('加载中');
        if (res.code === '0000') {
          this.$toast.hide()
          const result = res.result
          if (result.mobile) {
            this.bindMobile = result.mobile
          }
          if (result.alipayAcc) {
            this.alipayAccOld = result.alipayAcc
            this.hasBind = true
          }
        } else {
          this.$toast.hide()
          this.$toast.info(res.message)
          console.log('活动火爆，请稍后重试')
        }
      } catch (err) {
        this.$toast.hide()
        this.$toast.info('活动火爆，请稍后重试')
        console.log('err:', err)
      }
    },
    // 发送绑定的手机号的验证码
    async doGetBindSmsCaptcha () {
      let params = {
        param: {
          bizCode: 'ChangeAlipayAcc'
        }
      }
      let res = {}
      try {
        res = await getBindSmsCaptcha(params)
        this.$toast.loading('加载中', { duration: 0 });
        if (res.code === '0000') {
          this.$toast.hide()
          const result = res.result
          this.captchaUniqueId = result.uniqueId
          this.$toast.info('验证码已发送，请查收')
          this.countDown()
        } else {
          this.$toast.hide()
          this.$toast.info(res.message)
          console.log('活动火爆，请稍后重试')
        }
      } catch (err) {
        this.$toast.hide()
        this.$toast.info('活动火爆，请稍后重试')
        console.log('err:', err)
      }
    },
    // 设置支付宝账号
    async doSetAlipayAcc () {
      let params = {
        param: {
          alipayAcc: this.alipayAcc,
          captcha: this.captcha,
          captchaUniqueId: this.captchaUniqueId
        }
      }
      let res = {}
      try {
        res = await setAlipayAcc(params)
        this.$toast.loading('加载中', { duration: 0 });
        if (res.code === '0000') {
          this.$toast.hide()
          this.$toast.info('支付宝账号绑定成功')
          setTimeout(() => {
            this.$router.back()
          }, 1000)
        } else {
          this.$toast.hide()
          console.log('活动火爆，请稍后重试')
        }
      } catch (err) {
        this.$toast.hide()
        console.log('err:', err)
      }
    },
    sendSms () {
      if (!this.bindMobile) {
        this.$toast.info('请先绑定手机号码')
        setTimeout(() => {
          this.$router.push({name: 'editMobile'})
        })
        return false
      }
      this.doGetBindSmsCaptcha()
    },
    willSave () {
      if (!this.alipayAcc) {
        this.$toast.info('请输入支付宝账号')
        return
      }
      this.doSetAlipayAcc()
    },
    nextStep () {
      this.blockStep++
    },
    countDown () {
      this.countDownStatus = true
      this.timer = setInterval(() => {
        this.seconds--
        if (this.seconds === 0) {
          this.countDownStatus = false
          this.seconds = 60
          clearInterval(this.timer)
        }
      }, 1000)
    }
  }
};
</script>

<style lang="css" scoped>
.public-container{
  margin: unset;
}
.weui-btn.public-btn-user-edit-next{
  padding: unset;
}
.weui-btn.public-btn-user-edit{
  padding: unset;
}
</style>